.signin-wrapper {
  .signin {
    background-image: url(../../images/login-bg.png);
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: top;
    padding: 5rem 3rem 5rem 3rem;
    min-height: calc(100vh - 88px);
    overflow: hidden;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    @include media-breakpoint-down(sm) {
      padding: 4rem 1rem 4rem 1rem;
    }
    .form-wrapper {
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(76, 97, 149, 0.25);
      border-radius: 20px;
      padding: 2rem 2.188rem 2.5rem 2.188rem;
      h1 {
        font-size: 2.25rem;
        line-height: 3.125rem;
        font-family: $font-semibold;
        color: $secondary;
        margin-bottom: 1.813rem;
      }
      p {
        font-size: 1.625rem;
        line-height: 3.125rem;
        color: $secondary;
        margin-bottom: 2rem;
      }
      .form-floating {
        position: relative;
        label {
          background-color: #fff;
          left: 38px;
          top: 11px;
          transition: 0.2s ease all;
          -moz-transition: 0.2s ease all;
          -webkit-transition: 0.2s ease all;
          padding: 0 0.625rem;
          font-size: 1.063rem;
          color: #6a7084;
          font-family: $font-medium;
          height: auto;
          transform: none;
          opacity: 1;
        }
        i {
          position: absolute;
          left: 0;
          font-size: 1.063rem;
          width: 50px;
          height: 50px;
          display: flex;
          top: 1px;
          z-index: 1;
          align-items: center;
          justify-content: center;
          color: #9ca1b0;
        }
        .icon-seen {
          left: auto;
          right: 0;
        }
        img {
          left: auto;
          right: 14px;
          max-width: 1.2rem;
          position: absolute;
          top: 0;
          width: 50px;
          height: 50px;
        }
        input {
          border: 1.3px solid #c1c6d5;
          box-sizing: border-box;
          border-radius: 5px;
          height: 50px;
          margin-bottom: 1.438rem;
          font-size: 1.063rem;
          color: $secondary;
          padding: 0.6rem 1rem;
          padding-left: 3rem;
          &:focus,
          &:not(:placeholder-shown) {
            box-shadow: none;
            border: 2px solid $primary;
            ~ label {
              font-family: $font-semibold;
              font-size: 0.875rem;
              color: $primary;
              top: -11px;
              transition: 0.2s ease all;
              -moz-transition: 0.2s ease all;
              -webkit-transition: 0.2s ease all;
              opacity: 1;
            }
          }
        }
        .form-select {
          border: 1.3px solid #c1c6d5;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 0.6rem 1rem;
          padding-left: 3rem;
          height: 50px;
          margin-bottom: 1.25rem;
          font-size: 1.063rem;
          color: $secondary;
          background-position: right 1.2rem center;
          background-size: 13px 12px;
          &:focus,
          &:not([value='']) {
            box-shadow: none;
            border: 2px solid $primary;
            &:valid {
              ~ label {
                font-family: $font-semibold;
                font-size: 0.875rem;
                color: $primary;
                top: -11px;
                transition: 0.2s ease all;
                -moz-transition: 0.2s ease all;
                -webkit-transition: 0.2s ease all;
                opacity: 1;
              }
            }
          }
        }
        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          display: none;
        }
        ::-moz-placeholder {
          /* Firefox 19+ */
          display: none;
        }
        :-ms-input-placeholder {
          /* IE 10+ */
          display: none;
        }
        :-moz-placeholder {
          /* Firefox 18- */
          display: none;
        }
      }
      .form-check {
        display: flex;
        align-items: center;
        .form-check-input {
          width: 1.438rem;
          height: 1.438rem;
        }
        .form-check-label {
          margin-left: 1rem;
        }
      }
      .link {
        text-decoration: underline;
        color: $content;
        font-size: 1rem;
        line-height: 1.2rem;
      }
      .btn {
        font-size: 1.188rem;
        line-height: 1.425rem;
        height: 56px;
      }
      &.click-here {
        .click-box {
          background: #e9f4fc;
          border-radius: 5px;
          font-size: 1.75rem;
          line-height: 3.125rem;
          color: $primary;
          padding: 1.563rem;
          text-align: center;
          margin-bottom: 2.125rem;
          font-family: $font-semibold;
        }
        p {
          color: $content;
          font-size: 1.063rem;
          line-height: 1.688rem;
        }
      }
      @include media-breakpoint-down(sm) {
        h1 {
          font-size: 1.8rem;
          line-height: 2.125rem;
        }
        p {
          font-size: 1.3rem;
        }
      }
    }
  }
}

@import "../variables/variables";

.copy-link.not-allowed {
  cursor: not-allowed !important;
  color: #aab2cb !important;
  &:hover {
    cursor: not-allowed !important;
    color: #aab2cb !important;
  }
}
.btn-cancel {
  padding: 0.614rem 2rem !important;
}
.not-allowed {
  cursor: not-allowed;
}
.link {
  cursor: pointer !important;
  text-decoration: none !important;
}
.link-with-underline {
  color: #4b5060;
  cursor: pointer !important;
  text-decoration: underline !important;
}
.custom-status-label {
  &.queue {
    color: #a88c00 !important;
  }
  &.processing {
    color: #675ee8 !important;
  }
  &.failed {
    color: #db4444 !important;
  }
  &.ready {
    color: #23a868 !important;
  }
  &.disabled {
    cursor: not-allowed !important;
    color: #aab2cb !important;
    &:hover {
      cursor: not-allowed !important;
      color: #aab2cb !important;
    }
  }
}
.action-td {
  padding-left: 0rem !important;
  padding-right: 4rem !important;
}
.custom-pr-3 {
  padding-right: 3rem !important;
}
.custom-pl-2 {
  padding-right: 2rem !important;
}
.td-fit-content {
  padding: 0rem 2rem !important;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  .link {
    text-align: center;
    justify-content: center;
  }
  .btn-outline-danger {
    width: 7.5rem;
  }
  .btn-outline-blue {
    width: 7.5rem;
  }
}
.scene-status-label {
  color: #228be6 !important;
  // padding: 0.22rem 1.50rem;
  &.new {
    padding: 0.22rem 1.67rem;
    // width: 5rem;
  }
  &.exporting,
  &.importing {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .text-retry {
    font-size: 0.9rem;
    color: #6c757d !important;
    text-decoration: underline !important;
    cursor: pointer;
  }
}
.rounded-pill {
  text-transform: capitalize;
  &.queue {
    border: 1.3px solid #a88c00 !important;
    color: #a88c00 !important;
    background-color: #f8f8f8 !important;
  }
  &.processing {
    border: 1.3px solid #675ee8 !important;
    color: #675ee8 !important;
    background-color: #cfcee43d !important;
  }
  &.failed {
    border: 1.3px solid #db4444 !important;
    color: #db4444 !important;
    background-color: #f5e0e0 !important;
  }
  &.ready {
    border: 1.3px solid #23a868 !important;
    color: #23a868 !important;
    background-color: #fefefe !important;
  }
}
.text-success {
  color: #11b854 !important;
}
.add-document {
  .upload-file {
    .card {
      .file-link {
        .file-name-status {
          text-align: left !important;
        }
      }
    }
  }
}
.model-table{
  tbody{
    td{
      padding: 0.87rem 0.6rem 0.87rem 1rem !important;    
    }
  }
}
.table-backdrop.show {
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.document-drop-wrapper {
  position: relative;
  input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid;
  }
}
.form-label {
  width: auto !important;
}
.choose-plan {
  .custom-clickable {
    cursor: pointer;
  }
  .card {
    .title {
      font-size: 1.063rem;
      line-height: 1.275rem;
      color: $primary;
      position: absolute;
      bottom: -36px;
      left: 7.5rem;
    }
  }
}
.inner {
  &.edit-plan {
    .tab-content {
      padding-top: 2rem;
      &.configure-product {
        .choose-card {
          .card-wrapper {
            background-color: #fafafa;
            border: 1.2px solid #eeeeee;
            border-radius: 5px;
            padding: 2rem 2rem;
            .card-heading {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 0.8rem;
              .title {
                margin-bottom: 0.75rem;
                font-size: 1.063rem;
                line-height: 1.625rem;
                font-family: "Acumin-semibold";
              }
              .badge {
                background-color: #efb112;
                font-family: $font-semibold;
                padding: 0.5rem 0.973rem;
                font-size: 14px;
              }
            }
            &.active {
              border: 2px solid $primary;
              box-shadow: 0px 0px 5px 5px #e9f5ff;
              .title {
                font-size: 1.063rem;
                line-height: 1.275rem;
                color: $primary;
                position: absolute;
                bottom: -36px;
                left: 7.5rem;
              }
            }
            .card-content {
              padding: 0;
              .card {
                cursor: pointer;
                &.active {
                  border: 2px solid $primary;
                  box-shadow: 0px 0px 5px 5px #e9f5ff;
                }
              }

              .plan {
                .amount {
                  font-size: 3rem;
                  color: $secondary;
                  font-family: $font-bold;
                }
                p {
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.7rem;
                  .mo {
                    font-size: 3rem;
                    font-family: $font-bold;
                    color: #bfbfbf;
                  }
                  .per {
                    font-size: 1.188rem;
                    margin-left: 10px;
                  }
                }
                .total {
                  font-size: 1.25rem;
                  margin: 0;
                }
              }
            }
          }
          .badge-default {
            border: 1.3px solid #1a9afa;
            color: #ffffff;
            background-color: #1a9afa;
            font-size: 0.938rem;
            padding: 0.298rem 1.67rem;
          }
          .custom-default-card-button {
            padding: 0rem 1rem;
            margin-right: 1rem;
            margin-left: 1rem;
            cursor: default;
            pointer-events: none;

            color: #1a9afa;
            background-color: #edf7ff;
          }
          .custom-btn-delete {
            float: right;
            &:hover {
              color: #ffffff;
            }
          }
        }
        .add-card-wrapper {
          .new-card-stripe-element {
            background-color: white;
            padding: 10px 20px 11px !important;
            border-radius: 5px;
            width: 100%;
            border: 1px solid #afafaf;
            box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
            &.active {
              border: 2px solid #1a9afa;
              box-shadow: 0px 0px 5px 5px #e9f5ff;
            }
          }
        }
      }
    }
  }
  .card {
    &.privacyPolicy {
      h1 {
        font-size: 1.5rem;
        line-height: 1.625rem;
        color: $secondary;
        font-family: $font-bold;
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem;
      }
    }
  }
}
.float-right {
  float: right;
}
.btn-outline-blue {
  border-color: #1a9afa;
  color: #1a9afa;
  background-color: #edf7ff;
  &:hover {
    color: $white;
    background-color: #1a9afa;
  }
}
@font-face {
  font-family: "product-new";
  src: url("../icons/product-new.eot?kweybl");
  src: url("../icons/product-new.eot?kweybl#iefix") format("embedded-opentype"), url("../icons/product-new.ttf?kweybl") format("truetype"),
    url("../icons/product-new.woff?kweybl") format("woff"), url("../icons/product-new.svg?kweybl#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon-product-new {
  font-size: 3rem;
  font-family: "product-new" !important;
  &.active {
    color: #0c6bf6;
  }
}

.btn-user-manual {
  color: #ffffff;
  border-color: #e5bd4e;
  background-color: #e5bd4e;
  &:active,
  &:focus,
  &:hover {
    color: #ffffff !important;
    border-color: #e5bd4e !important;
    background-color: #e5bd4ee0 !important;
  }
}
.modal-download-user-manual {
  .card {
    &.active {
      border-color: #0c6bf6;
      color: #0c6bf6;
    }
  }
  .flex-container {
    display: flex;
    justify-content: center;
  }
}

.custom-btn-delete-card {
  &:hover {
    color: #ffffff;
  }
}
.card-success,
.card-error {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.invoices-table {
  td {
    vertical-align: middle;
    .btn {
      border-width: 1.3px;
      font-size: 0.938rem;
      padding: 0.2rem 1.67rem;
    }
  }
}
.custom-btn-outline-primary-sm {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.text-underline {
  text-decoration: underline;
}
.custom-btn-primary-card {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
.custom-name {
  word-break: break-all;
}
.btn.disabled {
  cursor: not-allowed;
}
header {
  .main-header {
    .navbar {
      .nav-right {
        .memory {
          .badge-red {
            color: #ffffff;
            background: #ef472e;
            font-size: 0.938rem;
            padding: 0.4rem 2.14rem;
          }
        }
      }
    }
  }
}
.tooltip-inner {
  font-size: 17px;
}
.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.under-maintenance-page {
  .text-primary {
    color: #707070 !important;
  }
}
.custom-text-maintenance {
  background-color: #d53d28 !important;
  color: #ffffff !important;
}
.scene-preview-wapper {
  display: inline-block !important;
  height: 50px;
  width: 89px;
  margin-right: 1rem;
  background-color: #f6f6f6;
  border-radius: 0.2rem;
  text-align: center;
  .scene-preview {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0.2rem;
    &.zoom {
      transition: transform 0.2s;
      margin: 0 auto;
    }
    &.zoom:hover {
      transform: scale(1.5);
    }
  }
}
.custom-scene-heading {
  padding-left: 1rem !important;
  vertical-align: middle;
  img {
    height: 30px;
  }
  .heading {
    padding-left: 0.5rem;
    vertical-align: middle;
  }
}
@media (min-width: 1200px) {
  .custom-wide-container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .custom-wide-container {
    max-width: 1300px;
  }
}
@media (min-width: 1600px) {
  .custom-wide-container {
    max-width: 1450px;
  }
}
@media (min-width: 1800px) {
  .custom-wide-container {
    max-width: 1650px;
  }
}
@media (min-width: 2000px) {
  .custom-wide-container {
    max-width: 1800px;
  }
}
.rotate {
  animation: rotation 1s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

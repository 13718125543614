@import 'node_modules/bootstrap/scss/functions';

@import 'node_modules/bootstrap/scss/mixins';

@import '../variables/variables';
@import '../variables/mixins';

@import 'node_modules/bootstrap/scss/variables';
@import 'common';
@import 'node_modules/bootstrap/scss/bootstrap';

//Bootstrap
@import 'bootstrap';

@import './utilities/utilities';
@import './components/components';
@import 'pages/pages';
@import 'fonts';

html,
body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: $font-medium;
  color: $content;
}
.page-wrapper {
  margin-top: 89px;
  background-color: $white;
  @include media-breakpoint-down(lg) {
    margin-top: 77px;
  }
}
.link,
.btn-link,
a {
  text-decoration: none;
}
ul {
  list-style: none;
}

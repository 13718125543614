@font-face {
  font-family: "primary";
  src: url("../../icons/primary.eot?bdz1ae");
  src: url("../../icons/primary.eot?bdz1ae#iefix") format("embedded-opentype"), url("../../icons/primary.ttf?bdz1ae") format("truetype"),
    url("../../icons/primary.woff?bdz1ae") format("woff"), url("../../icons/primary.svg?bdz1ae#primary") format("svg"), url("../../icons/primary.otf?bdz1ae#primary") format("otf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "primary" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\e900";
}
.icon-add-file:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e902";
}
.icon-copy:before {
  content: "\e903";
}
.icon-edit:before {
  content: "\e904";
}
.icon-email:before {
  content: "\e905";
}
.icon-file-setting:before {
  content: "\e906";
}
.icon-glob:before {
  content: "\e907";
}
.icon-key:before {
  content: "\e908";
}
.icon-lock:before {
  content: "\e909";
}
.icon-seen:before {
  content: "\e90a";
}
.icon-upload:before {
  content: "\e90b";
}
.icon-upload2:before {
  content: "\e90c";
}
.icon-upload3:before {
  content: "\e90d";
}
.icon-user:before {
  content: "\e90e";
}

.icon-english-version:before {
  content: "\e900";
}
.icon-intalian-version:before {
  content: "\e901";
}

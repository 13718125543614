.avatar-xs {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    background-color: $ultralite;
    overflow: hidden;
    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }
}
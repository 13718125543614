$theme: #1A9AFA;
$primary: #1A9AFA;
$secondary: #000000;
$content: #4B5060;
$light-primary :#CDEEFE;
$danger: #EF472E;
$lightyellow :#fffbcc;
$success:#0c6638;
$white:#fff;
$light-gray:#DFE0E6;
$light:#FAFAFA;
$dark:#313131;
$dark-ultra:#14122E;
$dark-medium:#1E1B43;
$ultra-light:#D2D2D2;
$lighten:#F6F6F6;
$ultralite:#E2F3FF;
$theme-colors: (
  "theme": $theme,
    "primary": $primary,
    "secondary": $secondary,
    "danger": $danger,
    "success":$success,
    "white" : $white,
    "content": $content,
);
  
$custom-colors: (
  "light-gray" :$light-gray,
  "lightyellow" :$lightyellow,
  "light": $light,
  "dark":$dark,
  "dark-medium":$dark-medium,
  "ultra-light":$ultra-light,
  "lighten":$lighten,
  "light-primary":$light-primary,
  "ultralite":$ultralite,
  "dark-ultra":$dark-ultra,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

$base-font:'Acumin Pro', sans-serif;

  $font-light: 'Acumin-light';
  $font-medium: 'Acumin-medium';
  $font-semibold: 'Acumin-semibold';
  $font-bold: 'Acumin-bold';

  $weights:(
    'medium': $font-medium,
    'bold': $font-bold,
    'light': $font-light,
    'semibold': $font-semibold
  );


//==================Container Breakpoint=============//
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1200px) !default;

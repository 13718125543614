footer {
    background-color: $white;
    padding: 3.75rem 0rem 0rem 0rem;
    .footer-title{
        font-size: 1rem;
        line-height: 1.75rem;
        font-family: $font-semibold;
        color: $secondary;
        margin-bottom: 1.5rem;
    }
    ul{
        li{
            margin-bottom: 1rem;
            a{
                color: #656565;
                font-size: 1rem;
                transition: all 0.2s ease-in-out;
                -webkit-transition: all 0.2s ease-in-out;
                &:hover{
                    color: $primary;
                    transition: all 0.2s ease-in-out;
                    -webkit-transition: all 0.2s ease-in-out;
                }
            }
        }
    }
    .footer-bottom{
        padding: 2.25rem 0;
        border-top: 1px solid rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }
        .copyright{
            p{
                font-size: 0.938rem;
                line-height: 1.625rem;
                color: #656565;
                margin: 0;
                a{
                    color: $secondary;
                    transition: all 0.2s ease-in-out;
                    -webkit-transition: all 0.2s ease-in-out;
                    &:hover{
                        color: $primary;
                        transition: all 0.2s ease-in-out;
                        -webkit-transition: all 0.2s ease-in-out;
                    }
                }
            }
        }
        .policy{
            margin-right: 2.688rem;
            a{
                font-size: 0.938rem;
                line-height: 1.625rem; 
                color: #656565;
                transition: all 0.2s ease-in-out;
                -webkit-transition: all 0.2s ease-in-out;
                &:hover{
                    color: $primary;
                    transition: all 0.2s ease-in-out;
                    -webkit-transition: all 0.2s ease-in-out;
                }
            }
            span{
                border-right: 1px solid rgba(0, 0, 0, 0.08);
                margin: 0 10px;
            }
        }
        .social-media{
            display: flex;
            align-items: center;
            li{
                margin-bottom: 0;
                a{
                    display: flex;
                    background-color: #f8f8f8;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    margin-left: 7px;
                    margin-right: 7px;
                    text-decoration: none;
                    -webkit-transition: 0.3s ease-in-out;
                    transition: 0.3s ease-in-out;
                    position: relative;
                    color: $secondary;
                    padding: 0.5rem;
                   img{
                       max-width: 1rem;
                   }
                    &:hover{
                        i{
                            color: $primary;
                            transition: all 0.2s ease-in-out;
                            -webkit-transition: all 0.2s ease-in-out;
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
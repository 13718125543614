@mixin padder($t:0rem, $l:0rem, $b:0rem, $r:0rem) {
  padding: $t $r $b $l;
}

$sides: (
"": "",
"t": "top",
"b": "bottom",
"l": "left",
"r": "right",
);


$breakpointsnew: (
  xs: 320px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1366px,
  xxxl: 1940px) !default;

$unit: "rem";

@each $breakName, $breakValue in $breakpointsnew {
  @each $sideName, $sideValue in $sides {
    @for $i from 0 through 20 {
      
      $property: if($sideName == '', '', -#{$sideValue});
      $space: $i * 1 / 2;
      $selector: '';

      @if $breakName != "" {
        $selector: #{$sideName}-#{$breakName}-#{$i};
      } @else {
        $selector: #{$sideName}-#{$i};
      }

      @if $breakName != "" {
        @media (min-width: $breakValue) {
          .m#{$selector} {
            margin#{$property}: #{$space + $unit} !important;;
          }
  
          .p#{$selector} {
            padding#{$property}: #{$space + $unit} !important;;
          }
        }
      } @else {
        .m#{$selector} {
          margin#{$property}: #{$space + $unit} !important;;
        }

        .p#{$selector} {
          padding#{$property}: #{$space + $unit} !important;;
        }
      }
    }
  }
}

.btn{
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    &:hover,&:focus,&:active{
        outline: none;
        box-shadow: none !important;
    }
    &:hover{
        transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
    }
}
.btn-link-primary{
    display: flex;
    align-items: center;
    padding: .60rem 1.11rem;
    font-size: 0.938rem;
    font-family: $font-semibold;
    color: $primary;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    line-height: 1.188rem;
    &:hover{
        color: $primary;
    }
}
.btn-primary,.btn-danger{
    color: $white;
    font-size: 1rem;
    font-family: $font-semibold;
    line-height: 1.125rem;
    border-radius: 5px;
    padding: .75rem 1.16rem;
    display: inline-flex;
    align-items: center;
    i{
        font-size: 1rem;
        margin-right: 0.625rem;
    }
    &:hover,&:focus,&:active{
        color: $white;
    }
}
.btn-outline-primary{
    color: $white;
    border-color: $white;
    position: relative;
    z-index: 1;
    transition: opacity 0.3s ease-in-out;
    font-size: 0.938rem;
    font-weight: $font-semibold;
    line-height: 1.125rem;
    &:hover,&:focus,&:active{
        color: $primary;
        border-color:   $white ;
        background-color: $white;
        transition: opacity 0.3s ease-in-out;
    }
}

.btn-link{
    font-family: $font-semibold;
}

.payment-tab {
  .payment-card {
    .card {
      padding: 0.875rem 1.563rem !important;
    }
  }
}
.add-card-wrapper {
  padding: 2rem 2rem;
  background: #fafafa;
  border-radius: 10px;
  /* text-align: center; */
  border: none;
  min-height: 100%;
  .title {
    margin-bottom: 0.75rem;
    font-size: 1.063rem;
    line-height: 1.625rem;
    font-family: 'Acumin-semibold';
  }
  .card {
    background-color: white;
    padding: 10px 20px 11px !important;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #afafaf;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  }
}

.pay-button {
  padding: 0.7rem 2rem;
  width: 100%;
  margin: 1rem 0;
  color: white;
  font-weight: bold;
  font-size: medium;
  background-color: #1a9afa;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}
.pay-button:disabled {
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}
.pay-button:disabled:hover {
  box-shadow: none;
}
.pay-button:hover {
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}
.custom-btn-delete-card {
  &:hover {
    color: #ffffff;
  }
}
#pills-review-order {
  .success-subscribe {
    background: #fafafa;
    border: 1px dashed #d7d7d7;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 2.938rem 1.125rem 2.188rem 1.125rem;
    text-align: center;
    .title {
      font-size: 1.5rem;
      line-height: 1.625rem;
      font-family: 'Acumin-semibold';
      margin-top: 1rem;
      margin-left: -1rem;
      color: #4b5060e3;
    }
  }
}

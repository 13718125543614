.inner {
  position: relative;
  padding: 3.938rem 0rem 3.938rem 0rem;
  overflow: hidden;
  background-repeat: no-repeat;
  height: 100%;
  background: $light-primary;
  z-index: 1;
  min-height: calc(100vh - 88px);
  &::before {
    content: '';
    background-image: url(../../images/inner-bg.png);
    background-size: contain;
    background-position: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 116px;
    height: 263px;
    background-repeat: no-repeat;
    z-index: -1;
  }
  &.scenes {
    .nav-pills {
      background-color: $white;
      box-shadow: 0px 0px 20px rgba(76, 97, 149, 0.25);
      border-radius: 8px;
      padding: 0.75rem 0.875rem;
      .nav-item {
        .nav-link {
          font-family: $font-medium;
          color: $content;
          font-size: 1.063rem;
          padding: 0.53rem 2rem;
          border-radius: 5px;
          &.active {
            font-family: $font-semibold;
            color: $white;
          }
        }
        @include media-breakpoint-down(lg) {
          flex-grow: 1;
          flex-basis: 30%;
          padding: 0.3rem;
          .nav-link {
            width: 100%;
            border: 1px solid #d7d7d7;
          }
        }
        @include media-breakpoint-down(md) {
          flex-basis: 50%;
        }
      }
    }
    .tab-content {
      background-color: $white;
      border-radius: 8px;
      padding: 1.563rem;
      .add-document {
        background: $light;
        border: 1px dashed #d7d7d7;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 2.938rem 1.125rem 2.188rem 1.125rem;
        text-align: center;
        .entry-icon {
          margin-bottom: 2.063rem;
          img {
            max-width: 3rem;
          }
        }
        .btn {
          font-size: 1.063rem;
          font-family: $font-medium;
          padding: 0.94rem 2.3rem;
          line-height: 1.25rem;
        }
        p {
          color: #5e607c;
        }
        .upload-file {
          .card {
            @extend %commanCard;
            border-radius: 5px;
            flex-direction: row;
            justify-content: space-between;
            padding: 0.718rem 1rem;
            overflow: hidden;
            .file-link {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              p {
                margin-bottom: 0;
              }
              .per {
                font-size: 1.063rem;
                font-family: $font-medium;
                margin-right: 1.813rem;
                color: $secondary;
              }
            }
            .btn {
              padding: 0.614rem 2.3rem;
              line-height: 1.275rem;
            }
          }
          @include media-breakpoint-down(md) {
            .card {
              flex-direction: column;
              .file-link {
                flex-direction: column;
                p {
                  margin-bottom: 1rem;
                }
                .par {
                  margin-right: 0;
                }
              }
              .btn {
                justify-content: center;
              }
            }
          }
        }
      }
      .table-wrapper {
        .table {
          box-sizing: border-box;
          border-radius: 5px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          margin-bottom: 0;
          > :not(:first-child) {
            border-top: none;
          }
          thead {
            background-color: $dark;
            th {
              color: $white;
              font-size: 1rem;
              line-height: 1.625rem;
              font-family: $font-semibold;
              padding: 0.782rem 1.625rem;
            }
          }
          tbody {
            tr {
              td {
                font-size: 1.063rem;
                line-height: 1.625rem;
                color: $content;
                // padding: .87rem 1.625rem;
                padding: 0.87rem 0.6rem 0.87rem 1.625rem;
                vertical-align: middle;
                .link {
                  color: $content;
                  font-size: 1rem;
                  line-height: 1.188rem;
                  display: flex;
                  align-items: center;
                  transition: all 0.3s ease-in-out;
                  -webkit-transition: all 0.3s ease-in-out;
                  i {
                    margin-right: 0.625rem;
                  }
                  &:hover {
                    color: $primary;
                    transition: all 0.3s ease-in-out;
                    -webkit-transition: all 0.3s ease-in-out;
                  }
                }
                .btn {
                  border-width: 1.3px;
                  font-size: 0.938rem;
                  padding: 0.22rem 1.67rem;
                }
                .btn-outline-danger {
                  border-color: #f63d3d;
                  color: #f63d3d;
                  background-color: #ffeded;
                  &:hover {
                    color: $white;
                    background-color: #f63d3d;
                  }
                }
                .badge-success {
                  border: 1.3px solid #23a868;
                  color: #23a868;
                  background-color: #f0fff8;
                  font-size: 0.938rem;
                  padding: 0.298rem 1.67rem;
                }
                .btn-outline-blue {
                  border-color: #1a9afa;
                  color: #1a9afa;
                  background-color: #edf7ff;
                  &:hover {
                    color: $white;
                    background-color: #1a9afa;
                  }
                }
              }
            }
          }
        }
      }
      .add-name {
        .btn {
          padding: 0.96rem 1.16rem;
        }
      }
      .frame-update {
        background-color: $light;
        padding: 2.188rem;
        border-radius: 10px;
        .entry-heading {
          font-family: $font-bold;
          color: $secondary;
        }
        label {
          font-size: 1.063rem;
        }
      }
    }
  }
  .card {
    &.comman {
      background: $white;
      border: none;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 1.938rem 1.563rem 2.188rem 1.563rem;
      h1 {
        font-size: 1.5rem;
        line-height: 1.625rem;
        color: $secondary;
        font-family: $font-bold;
        margin-bottom: 2.5rem;
      }
    }
  }
  &.licence {
    .card {
      .plan-detail {
        ul {
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1.563rem;
          li {
            text-align: center;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            padding: 1.688rem 1.688rem 1.188rem 1.688rem;
            &:last-child {
              border: none;
            }
            h3 {
              font-size: 1.25rem;
              color: $dark-ultra;
              line-height: 1.625rem;
              font-family: $font-semibold;
              margin-bottom: 0.938rem;
            }
            span {
              font-size: 1.063rem;
              line-height: 1.625rem;
            }
            .logo {
              img {
                max-width: 10rem;
              }
            }
          }
          @include media-breakpoint-down(lg) {
            flex-direction: column;
            li {
              border: none;
            }
          }
        }
        .btn {
          padding: 0.813rem 1.4rem;
        }
      }
      .subscription {
        .card {
          padding: 2rem 2rem;
          background: $light;
          border-radius: 10px;
          text-align: center;
          border: none;
          min-height: 100%;
          h4 {
            font-size: 1.25rem;
            line-height: 1.625rem;
            color: $dark-ultra;
            margin-bottom: 1.563rem;
          }
          span {
            font-size: 1.063rem;
            line-height: 1.625rem;
          }
        }
      }
    }
  }
  &.edit-plan {
    .card {
      padding: 0;
      .card-content {
        padding: 1.938rem 1.563rem 2.188rem 1.563rem;
      }
      .footer-btn {
        padding: 1.4rem;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        .btn {
          padding: 1.25rem 3.67rem;
          font-size: 1.188rem;
        }
      }
    }
    .nav-pills {
      justify-content: space-between;
      position: relative;
      z-index: 1;
      &::after {
        content: '';
        position: absolute;
        top: 11px;
        left: 0;
        height: 5px;
        width: 100%;
        background: $light;
        width: 100%;
        z-index: -1;
      }
      .nav-item {
        margin: 0 2rem;
        .nav-link {
          width: 1.75rem;
          height: 1.75rem;
          border-radius: 50%;
          border: 1px solid $content;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          background-color: $white;
          margin: 0 auto;
          span {
            border-radius: 50%;
            width: 1.125rem;
            height: 1.125rem;
            display: block;
            background-color: $content;
          }
          &.active {
            border: 1px solid $primary;
            span {
              background-color: $primary;
            }
          }
        }
        h2 {
          font-size: 1.063rem;
          line-height: 1.625rem;
          font-family: $font-semibold;
          margin-top: 1rem;
        }
      }
    }
    .tab-content {
      padding-top: 2rem;
      &.configure-product {
        .entry-heading {
          color: $secondary;
          font-family: $font-semibold;
        }
        .licence-num {
          display: flex;
          align-items: center;
          p {
            margin: 0;
          }
          .info {
            img {
              max-width: 1.3rem;
            }
          }
          input {
            width: 86px;
            height: 42px;
            padding: 0 1rem;
          }
        }
        .choose-plan {
          .card {
            border: 1.2px solid #eeeeee;
            border-radius: 5px;
            padding: 1.875rem 1.563rem;
            .card-heading {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 0.8rem;
              h5 {
                font-size: 1.375rem;
                line-height: 1.65rem;
                color: $secondary;
              }
              .badge {
                background-color: #efb112;
                font-family: $font-semibold;
                padding: 0.5rem 0.973rem;
                font-size: 14px;
              }
            }
            &.active {
              border: 2px solid $primary;
              box-shadow: 0px 0px 5px 5px #e9f5ff;
              .title {
                font-size: 1.063rem;
                line-height: 1.275rem;
                color: $primary;
                position: absolute;
                bottom: -36px;
                left: 7.5rem;
              }
            }
            .card-content {
              padding: 0;
              .plan {
                .amount {
                  font-size: 3rem;
                  color: $secondary;
                  font-family: $font-bold;
                }
                p {
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.7rem;
                  .mo {
                    font-size: 3rem;
                    font-family: $font-bold;
                    color: #bfbfbf;
                  }
                  .per {
                    font-size: 1.188rem;
                    margin-left: 10px;
                  }
                }
                .total {
                  font-size: 1.25rem;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tooltip-inner {
  background-color: $secondary;
  opacity: 1 !important;
  padding: 1rem;
  text-align: left;
  max-width: 350px;
}

header{
    .main-header{
        background-color: #fff;
        box-shadow: 0px 2px 10px rgba(58, 58, 58, 0.2);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
        transition: all 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        overflow: visible;
        .navbar{
            justify-content: space-between;
            z-index: 9;
            padding: 0;
            position: relative;
            .navbar-brand{
                img{
                    max-width: 11rem;
                    @include media-breakpoint-down(sm) {
                        max-width: 7rem;
                        margin: 0.7rem 0;
                    }
                }
                &:first-child{
                    border-right: 1px solid #EDEDED;
                    margin-right: 1.2rem;
                    padding-right: 0.8rem;
                }
            }
            .btn{
                padding: .813rem 1.66rem;
                display: flex;
                font-size: 0.938rem;
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
            .nav-left{
                padding: 1.178rem 0;
            }
            .nav-right{
                display: flex;
                align-items: center;
                .name{
                    font-size: 1.063rem;
                    line-height: 1.275rem;
                    font-family: $font-semibold;
                    margin-right: 12px;
                    color: $content;
                    margin-left: 0.813rem;
                }
                .dropdown{
                    margin-right: 1.8rem;
                    .dropdown-toggle{
                        display: flex;
                        align-items: center;
                        padding: 0.813rem 0;
                        &::after{
                            margin-top: 5px;
                            margin-left: 0;
                            border-top: 0.7em solid #C4C4C4;
                            border-right: 0.4em solid transparent;
                            border-bottom: 0;
                            border-left: 0.4em solid transparent;
                        }
                        .avatar-xs{
                            padding: 0.3rem
                        }
                    }
                    .dropdown-menu{
                        min-width: 15.7rem;
                        border: 1px solid rgba(0, 0, 0, 0.15);
                        border-radius: 2px;
                        li{                            
                            border-bottom: 1px solid #F2F2F2;
                            &:last-child{
                                border-bottom: 0;
                            }
                        }
                        .dropdown-item{
                            padding: 0.6rem 1rem;
                            font-size: 1.063rem;
                            color: $content;
                            transition: all 0.3s ease-in-out;
                            -webkit-transition: 0.3s ease-in-out;
                            &:hover{
                                background-color: $primary;
                                color: $white;
                                transition: all 0.3s ease-in-out;
                                -webkit-transition: 0.3s ease-in-out;
                                &.text-danger{
                                    color: #fff !important;
                                }
                            }
                            
                        }
                    }
                }
                .memory{
                    .badge-ultralite{
                        background: $ultralite;
                        color: $primary;
                        font-size: 0.938rem;
                        padding:0.4rem 1.9rem;
                        font-family: $font-semibold;
                    }
                    .badge-yellow{
                        color: #554A09;
                        background: $yellow;
                        font-size: 0.938rem;
                        padding:0.4rem 2.14rem;
                        font-family: $font-semibold;
                    }
                }
                .btn-outline-blue{
                    border-color: $primary;
                    color: $primary;
                    background-color: #fff;
                    border-radius: 5px;
                    font-size: 0.938rem;
                    padding-top: .7rem;
                    padding-bottom: .7rem;
                    &:hover{
                        color: #fff;
                        background-color: $primary;
                    }
                }
            }
            .mobile-menu{
                .navbar-toggler{
                    border: none;
                    img{
                        max-width: 1.5rem;
                        @include media-breakpoint-down(sm) {
                            max-width: 1.2rem;
                        }
                    }
                    &::after{
                        display: none;
                    }
                    &:focus{
                        box-shadow: none;
                    }
                }
                .dropdown-menu[data-bs-popper]{
                    left: auto;
                    right: 0;
                    
                }
                .dropdown-menu{
                    border: 1px solid rgba(85, 96, 138, 0.1);
                    box-sizing: border-box;
                    box-shadow: 0px 3px 3px rgba(197, 197, 197, 0.25);
                    .dropdown-item {
                        color: $secondary;
                        padding: 0.5rem 1rem;
                    }
                    span{
                        font-size: 1.063rem;
                        display: block;
                        padding: 0.5rem 1rem;
                        font-family: $font-semibold;
                        color: $primary;
                    }
                }
            }
            
        }
    }
}
//Inner Title and Heading
.entry-title {
  font-size: 2.375rem;
  line-height: 2.85rem;
  font-family: $font-bold;
  color: $secondary;
  margin-bottom: 1rem;
  @include media-breakpoint-down(lg) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}
.entry-heading {
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-family: $font-medium;
  color: $content;
  @include media-breakpoint-down(lg) {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
}
%commanSectionData {
  position: relative;
  @include padder($t: 6.25rem, $b: 6.25rem);
  @include media-breakpoint-down(lg) {
    @include padder($t: 4rem, $b: 4rem);
  }
  @include media-breakpoint-down(md) {
    @include padder($t: 3rem, $b: 3rem);
  }
}
%commanBg {
  background: $white;
}
%commanCard {
  background: $white;
  border: 1px solid rgba(85, 96, 138, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 3px 3px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  padding: 1.4rem 1.125rem;
  min-height: 100%;
}
.entry-structure {
  @extend %commanBg;
  @extend %commanSectionData;
}

.entry-structure-bg {
  // background: url('../images/bg-2.png');
  @extend %commanSectionData;
  background-size: cover;
  background-position: top;
  .entry-title {
    color: $white;
  }
  .entry-heading {
    color: $light-gray;
  }
}

.breadcrumb {
  li {
    a {
      color: $secondary;
    }
    &.active {
      font-family: $font-semibold;
    }
  }
}

//new
$modal-header-border-color: rgba(0, 0, 0, 0.1);
$modal-footer-border-color: rgba(0, 0, 0, 0.1);
$modal-content-border-radius: 10px;
$modal-header-padding: 1.313rem 1.875rem;
$modal-lg: 737px;
$modal-md: 577px;
// $form-select-indicator:             url("../images/down.svg");

.form-group {
  .form-control {
    border: 1.3px solid #c1c6d5;
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;
    font-size: 1.063rem;
    padding-left: 1.25rem;
    color: $secondary;
    &:focus {
      box-shadow: none;
      border: 2px solid $primary;
    }
  }
  .form-label {
    font-size: 1.125rem;
    color: $content;
    font-family: $font-semibold;
    margin-bottom: 1rem;
  }
}

.modal {
  .modal-header {
    padding: 1.563rem 1.875rem;
    .modal-title {
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: $secondary;
      font-family: $font-semibold;
    }
    .btn-close {
      background: url('../../images/close.svg');
      width: 8px;
      height: 8px;
      background-size: cover;
      opacity: 1;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .modal-body {
    padding: 2rem 1.875rem;
  }
}
.modal-download {
  .modal-header {
    border: none;
  }
  .modal-body {
    h4 {
      font-size: 1.188rem;
      line-height: 1.425rem;
      font-family: $font-semibold;
      color: $secondary;
      margin-bottom: 1.625rem;
    }
    .card {
      padding: 2.063rem 1.75rem 1.438rem 1.75rem;
      text-align: center;
      border: 1px solid #c1c6d4;
      box-sizing: border-box;
      border-radius: 5px;
      .card-thumb {
        img {
          max-width: 2.1rem;
        }
      }
      h5 {
        font-size: 1.063rem;
        line-height: 1.275rem;
        font-family: $font-semibold;
        margin-top: 1.3rem;
        margin-bottom: 0;
      }
    }
    .launch {
      padding: 1.13rem 5.4rem;
      font-size: 1.188rem;
    }
  }
}
.modal-mail {
  .modal-header {
    border: none;
  }
  .modal-body {
    h4 {
      font-size: 2.25rem;
      line-height: 2.7rem;
      color: $secondary;
      font-family: $font-bold;
    }
    p {
      font-size: 1.063rem;
      line-height: 1.688rem;
    }
  }
}
.modal-delete {
  .modal-title {
    font-size: 1.125rem !important;
    color: $content !important;
    font-family: $font-medium !important;
  }
  .modal-header,
  .modal-footer {
    border: none;
  }
}
.modal-cancel-sub {
  .modal-header {
    border: none;
  }
  .modal-body {
    h4 {
      font-size: 2.25rem;
      line-height: 2.7rem;
      color: $secondary;
      font-family: $font-bold;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.063rem;
      line-height: 1.688rem;
      strong {
        font-family: $font-semibold;
      }
    }
  }
}
